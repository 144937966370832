import { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./styles.scss";
import "./global.css";
import { Provider } from "react-redux";
import store from "./store/index.ts";

import "./common/styles/common.scss";
import Loader from "./common/components/Loader/index.tsx";
import ProtectedRoute from "./layouts/protectedRoute.tsx";
// const Home = lazy(() => import("./pages/Home"));
// const About = lazy(() => import("./pages/About"));
const Header = lazy(() => import("./common/Header/index"));
const LoginScreen = lazy(() => import("./pages/LoginScreen/index.tsx"));
const CreateMeeting = lazy(() => import("./pages/CreateMeeting/index.tsx"));
const Zoom = lazy(() => import("./pages/Zoom/index.tsx"));
const UserStatus = lazy(() => import("./pages/UserStatus/index.tsx"));
const VerifyUserFace = lazy(() => import("./pages/VerifyUserFace/index.tsx"));
const RegistrationStatus = lazy(
  () => import("./pages/RegistrationStatus/index.tsx"),
);
const Profile = lazy(() => import("./pages/Profile/index.tsx"));

export default function App() {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(apiUrl);
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Suspense fallback={<Loader type="large" />}>
            <Header />
            <Routes>
              {/* <Route path="/header" element={<Header />} /> */}
              <Route path="/" element={<LoginScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/verifyuserface" element={<VerifyUserFace />} />

              <Route element={<ProtectedRoute />}>
                <Route path="/createmeeting" element={<CreateMeeting />} />
                <Route path="/zoom" element={<Zoom />} />
                <Route path="/userstatus" element={<UserStatus />} />
                <Route
                  path="/registrationstatus"
                  element={<RegistrationStatus />}
                />
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Routes>
          </Suspense>
        </div>
      </Router>
    </Provider>
  );
}
