import { createSlice } from "@reduxjs/toolkit";

interface seekerState {
  seekerData: unknown;
}

const initialState: seekerState = {
  seekerData: {},
};

const seekerDataSlice = createSlice({
  name: "seekerState",
  initialState,
  reducers: {
    addSeekerData: (state, data) => {
      state.seekerData = data.payload;
    },
  },
});

export const { addSeekerData } = seekerDataSlice.actions;

export default seekerDataSlice.reducer;
